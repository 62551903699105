export const EXHIBITION_IDS = {
  EXHIBITION_38: "3KaDSaWVCtM0LUycKh6kRM",
  EXHIBITION_36: "6yK6WPxTqNQFP99KWFbtOw",
  EXHIBITION_35: "4U5yDpBlnN67yEQqFR7F1Z",
  EXHIBITION_OS: "4PO9PebVUghSmQTic5zoCx",
  EXHIBITION_34: "77eY9M1yR9qx0917cK7Rjp",
  EXHIBITION_33: "5fXGeKJH182dHrUI9sIcTK",
  EXHIBITION_32: "2RFjYEHDLgCaMO640zTB1Z",
  EXHIBITION_31: "40OWUkeS5XoDxqmYJ3zvhz",
  EXHIBITION_30: "2ukLyYNqWnW4emoUQ8RJ90",
  EXHIBITION_29: "2EbYkaqgCRk2Whj1Rk7wn9",
  EXHIBITION_28: "3SMQbUsyvuKTDBBc7NAY9q",
  EXHIBITION_27: "2iRlpfpeEeKNkjo0TzkJrq",
  EXHIBITION_26: "1gWXB1EdeW3PFWAxlMG7Uy",
  EXHIBITION_25: "3HFYSeMH9mLWhL8EIs7V2p",
  EXHIBITION_24: "6kJwQAw5fakgVRWxbWTYmT",
  EXHIBITION_23: "5rEcshh9NBFgcJdzWSnHcj",
  EXHIBITION_22: "58nNUIHpT8h3c0EnYR9SPK",
  EXHIBITION_21: "P3TSGo6hn8FxpQrMXxpc1",
  EXHIBITION_20: "6lig9MTvFBTvuGGh8WzaeS",
  EXHIBITION_OS18: "7irb7Tx4EAs1tPSj6fqbCe",
  EXHIBITION_19: "35zq7tytYCDoNYN8UmEU6J",
  EXHIBITION_18: "1HODHH8KwOoNB5ZzfokVWy",
  EXHIBITION_17: "IbI9kjVxK0xge57szI6Oo",
  EXHIBITION_16: "5IhkALEP7ne5KC6tLrGN75",
  EXHIBITION_15: "rXUCoNrvrsOjolSvnGaHp",
  EXHIBITION_OS17: "3RzbfR36jX8PrY0w6bTjWZ",
  EXHIBITION_14: "4Jpr2gLZWKdHii6AtYSd0R",
  EXHIBITION_13: "SJb71L6J22c3IFhWUPL5m",
  EXHIBITION_12: "2L7nqcJZW58kKKJD7dr89z",
  EXHIBITION_11: "3S7P30jJY7faLtuKdzdrFB",
  EXHIBITION_10: "35II4pfHKv5wMmY1e2OBNY",
  EXHIBITION_09: "31g5qlnloSbcgE5MsApZdL",
  EXHIBITION_08: "2rkkpHjWyrLeMDkOw8rCIA",
  EXHIBITION_07: "1kqmnxnVv7a3DxlGSt0Ixas",
  EXHIBITION_06: "4sg3kdnjeo41YfoK6YKwRY",
  EXHIBITION_05: "6iEh1UHGPfvHZTUzY1wbJ0",
  EXHIBITION_04: "52CeHU3UbgkJ6ZqwbxhD9",
  EXHIBITION_03: "63K18xk6GiHu3C1lC8KyrF",
  EXHIBITION_02: "2UdwwJW87zLvOR3zQNg38N",
  EXHIBITION_01: "35I5B8nTmYWGoung1f2ohJ",
};
